import React from 'react';
import ImageGallery from 'react-image-gallery';

const images = [
   {
     original: './img/1.jpg',
     thumbnail: './img/1.jpg',
   },
   {
      original: './img/2.jpg',
      thumbnail: './img/2.jpg',
    },
    {
      original: './img/3.jpg',
      thumbnail: './img/3.jpg',
    },
    {
      original: './img/4.jpg',
      thumbnail: './img/4.jpg',
    },
    {
      original: './img/5.jpg',
      thumbnail: './img/5.jpg',
    },
    {
      original: './img/6.jpg',
      thumbnail: './img/6.jpg',
    },
    {
      original: './img/7.jpg',
      thumbnail: './img/7.jpg',
    },
    {
      original: './img/8.jpg',
      thumbnail: './img/8.jpg',
    },
    {
      original: './img/9.jpg',
      thumbnail: './img/9.jpg',
    }
 ];

const Imagegalery = () => {
   return (
      <div className="container mt-3 pb-5">
        <h2 className="mavi text-center">ÜRÜNLER</h2>
         <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} className="" />
      </div>
   )
}


export default Imagegalery
