import React from 'react';


const Hakkimda = () => {
   return (
      <div className="hakkimda">
         <div className="container">

            <h2 className="text-center mavi mb-5">HAKKIMIZDA</h2>
                  <p className="font-20">
                  Şirketimiz Tek-Jen Jeneratör olarak Ostim OSB’de faaliyetine başlamıştır. İlk olarak İŞBİR Jeneratör’de jeneratör temellerini attığımız sektörde 19 yıllık tecrübemizle hizmet vermeye devam etmekteyiz.
                  </p>
                  <p className="font-20">
                     Müşteri talepleri ve kendi kalitemiz doğrultusunda sektörde en iyi servis hizmetini verebilmeyi amaç etmekteyiz.
                  </p>


         </div>
         
      </div>
   )
}

export default Hakkimda
