import React, {useEffect} from 'react';
import {Nav, Navbar} from 'react-bootstrap';

const Header = ({ history }) => {

   function getActiveClassName(path) {
       return history.location.pathname === path ? 'active' : '';
   }


   return (
         <Navbar bg="dark" variant="dark" expand="lg">
            <div className="container">
            <Navbar.Brand href="/">Tekjen Jeneratör</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
               <Nav className="ml-auto">
                  <Nav.Link className={getActiveClassName('/')} href="/">Anasayfa</Nav.Link>
                  <Nav.Link className={getActiveClassName('/hakkimizda')} href="/hakkimizda">Hakkımızda</Nav.Link>
                  <Nav.Link className={getActiveClassName('/hizmetlerimiz')} href="/hizmetlerimiz">Hizmetlerimiz</Nav.Link>
                  <Nav.Link className={getActiveClassName('/teknik-bilgi')} href="/teknik-bilgi">Teknik Bilgi</Nav.Link>
                  <Nav.Link className={getActiveClassName('/iletisim')} href="/iletisim">İletişim</Nav.Link>
               </Nav>
            </Navbar.Collapse>
            </div>
         </Navbar>
   )
}

export default Header
