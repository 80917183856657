import React from 'react';

const Teknikbilgi = () => {
    return (
        <div className="container pt-5 pb-3"> 
            <h3 className="pb-5">Güç ve Yer Tespiti</h3>

            <p>
                Jeneratör alırken önce ihtiyacınız olan gücün tespit edilmesi
                ve jeneratörün nerede çalışabileceğinin tespit edilmesi gerekmektedir.
            </p>

            <p>
                Şirketimizce hazırlanan tespitler sonrasında müşterilerimize uygun jeneratör sunulur.
            </p>

        </div>
    )
}

export default Teknikbilgi

