import React from "react";

const Logo = () => {
  return (
    <div className="container">
      <div className="row mt-2">
        <div className="col"><img src="./img/işbirlogo.jpg" className="img-fluid logo" /></div>
        <div className="col"><img src="./img/volvologo.jpg" className="img-fluid logo" /></div>
        <div className="col"><img src="./img/aksalogo.jpg" className="img-fluid logo" /></div>
        <div className="col"><img src="./img/cumnislogo.jpg" className="img-fluid logo" /></div>
      </div>

      <div className="row mt-3">
        <div className="col"><img src="./img/emsalogo.jpg" className="img-fluid logo" /></div>
        <div className="col"><img src="./img/deutzlogo.jpg" className="img-fluid logo" /></div>
        <div className="col"><img src="./img/teksanlogo.jpg" className="img-fluid logo" /></div>
        <div className="col"><img src="./img/perkinslogo.jpg" className="img-fluid logo" /></div>
      </div>
    </div>
  );
};

export default Logo;
