import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Home from './pages/home/Home';
import Layout from './Layout/Layout';
import Teknikbilgi from './pages/Teknikbilgi';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();




function App() {
  return (
    <div className="App">
       <Router history={history}>
        <div className="">
        <Layout history={history}>
            <Route path="/" exact component={Home}/>
            <Route path="/hakkimizda" component={About}/>
            <Route path="/hizmetlerimiz" component={Services}/>
            <Route path="/iletisim" component={Contact}/>
            <Route path="/teknik-bilgi" component={Teknikbilgi}/>
          </Layout>
        </div>
      </Router>
    </div>
  );
}

export default App;
