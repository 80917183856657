import React from "react";
import { Row, Col } from "react-bootstrap";

const Services = () => {
  return (
    <div className="" style={{ marginTop: "70px", marginBottom: "50px" }}>
      <h3 className="mb-3 text-center "> HİZMETLERİMİZ</h3>

      <div className="container">
        <Row className="mb-3 mt-5">
          <h5 className="mb-3">PERİYODİK BAKIM</h5>
          <div className="col-lg-6 col-sm-12">
            <img
              src="/img/6.jpg"
              className="img-fluid"
              style={{ maxWidth: "400px" }}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <p className="font-18">
              Jeneratörlerin ihtiyaç halinde sorunsuz çalışabilmesi, meydana
              gelecek arızaların en kısa sürede giderilmesi için periyodik
              olarak kontrol edilmesi gerekmektedir. Meydana gelen arızalar
              anlık ve geçici çözümlerle giderilmesi için periyodik olarak
              kontol edilmesi gerekmektedir. Meydana gelen arızaların anlık ve
              geçici çözümlerle giderilmesi ileride daha büyükj arızalara ve
              nakdi kayıplara sebep olmaktadır. Jeneratörünüze periyodik bakım
              sözleşmesi yaparak jeneratörünüzün sorunsuz çalışmasına, arıza
              oluşmadan önlemesini sağlar. Oluşan arızayı en kısa sürede kalıcı
              olarak gidermiş oluruz. Ayrıca bakım anlaşması yaptığınızda
              oluşabilecek arızalarda, anlaşma haricinden fazla ücret
              ödemezsiniz. Firmamızdan periyodik bakım anlaşması talebinde
              bulunabilirsiniz.
            </p>
          </div>
        </Row>
      </div>

      <div className="bg-light py-5">
        <div className="container">
          <h3>YEDEK PARÇA</h3>
          <Row>
            <Col className="col-lg-6 col-sm-12">
              <p className="font-18">
                {" "}
                Jeneratörünüzün kullanım ömrünü uzatmak için periyodik bakım
                yapılmasının yanında, orjinal yedek parça kullanmak önemlidir.
              </p>
              <p className="font-18">
                Jeneratörünüze ait orjinal yedek parça ihtiyaçlarınızı
                şirketimizden temin edebilirsiniz.
              </p>
            </Col>

            <Col className="col-lg-6 col-sm-12">
              <img src="/img/yedek-parca.jpg" className="img-fluid" />
            </Col>
          </Row>

          <Row className="pt-5 mb-3 d-flex justify-content-between">
            <div className="col-lg-4 col-sm-12">
              <img src="/img/yedek1.jpg" className="img-fluid" />
            </div>
            <div className="col-lg-4 col-sm-12">
              <img src="/img/yedek2.jpg" className="img-fluid" />
            </div>
            <div className="col-lg-4 col-sm-12">
              <img src="/img/yedek3.jpg" className="img-fluid" />
            </div>
          </Row>
        </div>
      </div>

      <div className="container">
        <Row className="mb-3 mt-5">
          <h5 className="mb-3"> GÜÇ ve YER TESPİTİ</h5>
          <div className="col-lg-6 col-sm-12">
            <img src="/img/4.jpg" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-sm-12">
            <p className="font-18">
              Jeneratör alırken öncelikle ihtiyacınız olan gücün tespit edilmesi
              ve jeneratörün nerede çalışabileceğinin tespit edilmesi
              gereklidir.
            </p>
            <p className="font-18">
              Firmamıza hazırlanan tespitler sonucunda müşterilerimizle bilgiler
              paylaşılıp uygun jeneratör teklifi sunulur.
            </p>
          </div>
        </Row>
      </div>

      <div className="container">
        <Row className="mb-3 mt-5">
          <h5 className="mb-3"> MONTAJ ve DEVREYE ALMA</h5>
          <div className="col-lg-6 col-sm-12">
            <img
              src="/img/6.jpg"
              className="img-fluid"
              style={{ maxWidth: "400px" }}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <p className="font-18">
              Güç ve yer tespitine uygun olarak, standartlara uygun kuvvet
              kablosu, kumanda kablosu ve topraklama kablosu bağlantıları
              yapılır. 
              </p>
              <p className="font-18">Jeneratörün çalıştırma işlemi yapılıp tüm arıza testleri
              tek tek denenir. Kullanıcı eğitimi verilerek jeneratör teslim
              edilir.
            </p>
          </div>
        </Row>
      </div>

      <div className="container">
        <Row className=" mb-3 mt-5">
          <h5 className="mb-3"> 2. EL SATIŞ – KİRALAMA</h5>
          <div className="col-lg-6 col-sm-12">
            <p className="font-18">
              Yatırım planlarınızda sıfır jeneratör almak yerine ihtiyacınızı
              karşılayabilecek ikinci el jeneratör almak daha uygun olabilir.
              Bünyemizde bulunan ikinci el jeneratörler tüm bakımları yapılmış,
              her türlü testleri yapılmış olarak teslim edilmektedir. İkinci el
              jeneratörlerimizde bakım anlaşması yapılması durumunda garanti
              süresi verilmektedir.
            </p>
            <p className="font-18">
              Geçici sürelerle jeneratöre ihtiyacınız olduğunda benzinli ve dizel
              jeneratör grupları ile hizmet vermekteyiz.
            </p>
          </div>
          <div div className="col-lg-6 col-sm-12">
            <img src="/img/5.jpg" className="img-fluid" />
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Services;
