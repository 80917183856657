import React, {useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';



const Layout = ({history, children}) => {

   return (
      <div>
         <Header history={history} />
         <main>
          {children}
         </main>
         <Footer/>
      </div>
   )
}

export default Layout