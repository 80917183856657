import React from "react";
import { Carousel, Button, Row, Col } from "react-bootstrap/";

const Slider2 = () => {
  return (
    <div className="container">
      <Carousel >

        <Carousel.Item >
            <div class="row align-items-center">
                <div class="col-xs-12 col-lg-6 py-2">
                <img src="./img/slayt30.jpg" className="img-fluid"/>
                </div>
                <div class="col-xs-12 col-lg-6 py-2">
                <img src="./img/slayt21.jpg" className="img-fluid"/>
                </div>
            </div>
        </Carousel.Item>

        <Carousel.Item >
            <div class="row align-items-center">
                <div class="col-xs-12 col-lg-6 py-2">
                    
                    <img src="./img/slayt22.jpg" className="img-fluid"/>
                </div>
                <div class="col-xs-12 col-lg-6 py-2">
                <img src="./img/slayt31.jpg" className="img-fluid"/>
                </div>
            </div>
        </Carousel.Item>

      </Carousel>
    </div>
  );
};

export default Slider2;

