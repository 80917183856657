import React from 'react';
import {Row, Col} from 'react-bootstrap';

const About = () => {
   return (
      <div className="container "style={{marginTop:'70px', marginBottom:'50px'}} >
           <h3 className="mb-4"> Hakkımızda</h3>
            <Row>
               <div className="col-lg-6 col-sm-12">
                     <p className="font-18">
                     Şirketimiz Tek-Jen Jeneratör olarak Ostim OSB’de faaliyetine başlamıştır. İlk olarak İŞBİR Jeneratör’de jeneratör temellerini attığımız sektörde 19 yıllık tecrübemizle hizmet vermeye devam etmekteyiz.


                     </p>
                     <p className="font-18">
                     Müşteri talepleri ve kendi kalitemiz doğrultusunda sektörde en iyi servis hizmetini verebilmeyi amaç etmekteyiz.
                     </p>
               </div>
               <div className="col-lg-6 col-sm-12">
                  <img src="/img/slayt1.jpg" className="img-fluid"/>
               </div>

               </Row>

      </div>
   )
}

export default About
