import React from "react";
import {Row, Col} from 'react-bootstrap';

const Footer = () => {
  return (
    <div id="contact" className=" bg-dark py-5 mt-5 overlay" >
      <div className="container">
        <h2 className="display-5 text-warning ">İLETİŞİME GEÇİNİZ</h2>
        <Row>

          <Col md="12" lg="6" className="pt-5">
            <Row>

              <Col sm="6">
                <div className="input-group">
                  <div className="input-group-text rounded-0 bg-warning border-warning text-white">
                    <i className="bi bi-person-fill"> </i>
                  </div>
                  <input
                    type="text"
                    className="form-control rounded-0 border-warning text-white bg-transparent"
                    placeholder="İSİM  *"
                    required
                  />
                </div>
              </Col>

              <Col sm="6">
                <div className="input-group">
                  <div className="input-group-text rounded-0 bg-warning border-warning text-white">
                    <i className="bi bi-at"> </i>
                  </div>
                  <input
                    type="email"
                    className="form-control rounded-0 border-warning text-white bg-transparent"
                    placeholder="EMAIL *"
                    required
                  />
                </div>
              </Col>

              <Col sm="12" className="py-5" >
                <div className="input-group">
                  <div className="input-group-text rounded-0 bg-warning border-warning text-white">
                    <i className="bi bi-envelope-fill"> </i>
                  </div>
                  <textarea
                    className="form-control rounded-0 border-warning text-white bg-transparent"
                    placeholder="MESAJ *"
                    rows="5"
                    required
                  > </textarea>
                </div>
              </Col>

              <div className="d-grid">
                <button
                  className="btn btn-outline-warning rounded-0 text-uppercase ml-3"
                  type="submit"
                >
                  MESAJ GÖNDER
                </button>
              </div>
            </Row>
          </Col>

          <Col md="12" lg="6" className="pl-4 pt-5">
            <Row>
              <table className="table border-0 d-flex h-100 flex-column justify-content-center">
                <tbody>

                  <tr className="d-flex justify-content-between mb-3">
                      <span className="text-light"><h5>Ostim OSB Mah. Enerji Cad. No:1/N Yenimahalle-Ankara /TÜRKİYE</h5></span>
                      <span className="bi bi-geo-alt-fill text-warning" style={{fontSize: '2rem'}}> </span>
                  </tr>

                  <tr className="d-flex justify-content-between mb-3">
                      <span className="text-light"><h5>info@tekjenjenerator.com </h5></span>
                      <span className="bi bi-envelope text-warning" style={{fontSize: '2rem'}}> </span>
                  </tr>

                  <tr className="d-flex justify-content-between mb-3">
                      <span className="text-light"><h5>0312 502 00 96  -  0532 370 93 37</h5></span>
                      <span className="bi bi-telephone-fill text-warning" style={{fontSize: '2rem'}}> </span>
                  </tr>

                  <tr className="d-flex justify-content-between  ">
                    <span className="text-light"> <i className="bi bi-facebook"style={{fontSize: '2.0rem'}}> </i></span>
                    <span className="text-light"> <i className="bi bi-twitter"style={{fontSize: '2.0rem'}}> </i></span>
                    <span className="text-light"> <i className="bi bi-youtube"style={{fontSize: '2.0rem'}}> </i></span>
                    <span className="text-light"> <i className="bi bi-google"style={{fontSize: '2.0rem'}}> </i></span>
                    <span className="text-light"> <i className="bi bi-instagram" style={{fontSize: '2.0rem'}}> </i></span>
                    <span className="bi bi-eye-fill text-warning" style={{fontSize: '2.2rem'}}> </span>
                  </tr>

                </tbody>
              </table>
            </Row>
          </Col>

        </Row>
      </div>
    </div>
  );
};

export default Footer;
