import React from "react";

const Section1 = () => {
  return (
    <div>
      <div className="section1 p-5 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                fill="currentColor"
                className="bi bi-check-circle mavi"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
              <h3 className="pt-3 mavi">Güvenilir İş</h3>
              <p>
                Yılların verdiği tecrübe ile kaliteli işçiliği siz müşterilerimize sunuyor.
              </p>
            </div>
            <div className="col-md-4 mb-4 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                fill="currentColor"
                class="bi bi-graph-up mavi"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z"
                />
              </svg>
              <h3 className="pt-3 mavi">19 Yıllık Tecrübe </h3>
              <p>
              Jeneratör sektöründe 19 yıllık tecrübemizle hizmet vermeye devam etmekteyiz.
              </p>
            </div>
            <div className="col-md-4 mb-4 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                fill="currentColor"
                class="bi bi-hand-thumbs-up mavi"
                viewBox="0 0 16 16"
              >
                <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
              </svg>
              <h3 className="pt-3 mavi">Müşteri Memnuniyeti</h3>
              <p>
                  Müşteri talepleri ve kendi kalitemiz doğrultusunda sektörde en iyi servis hizmetini verebilmeyi amaç edinmiştir.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
