import React from 'react';
import Slider from './Slider';
import Section1 from './Section1';
import SectionAbout from './SectionAbout';
import Section3 from './Section3';
import Hakkimda from './Hakkimda';
import Imagegalery from './../../components/Imagegalery';
import Newslider from './Newslider';
import Logo from './Logo'



const Home = () => {
   return (
      <div>
         <Slider/>
         <Logo/>
         <Hakkimda/>
         <Imagegalery/>
         <Section1/>
      </div>
   )
}

export default Home
